import { Moment } from "moment";
import { IncreasedHours } from "../constants";
import { EventDataType, PlanningEventOwner } from "../enumerations";
import { AbstractDynamicFile, Department, DictionaryNumber, DictionaryString, EventClockedCalculated, EventTimeclockStatus, Group, POI, StaffType, TypeOfVacations, TypeOfVehicle, User, UserEducations, UserHoursSummary, UserHoursSummaryWithContractDetails, UserOnlyId, UserSummary } from "./generalTypes";
import { CellIndexes, ContractDataPerf, EventCalculated, EventCopyErrorCodes, EventCopyErrorType, PlanningColor, Project, RRuleOptions, TypeOfDay, TypeOfDayOff, UserContractSmall, UserShort } from "./planningTypes";
import { BalanceOfTypeOfDaysOff, MonthlyReportEvent, MonthlyReportEventBreaktime, MonthlyReportEventOvertime, ReportSummaryError, UserEffectiveHoursAbstract } from "./reportTypes";

/**
 * Create News body request
 */
export interface CreateNewsBody {
    is_instructor: boolean;
    is_client: boolean;
    title: string;
    title_fr?: string;
    title_de?: string;
    title_it?: string;
    text: string;
    text_fr?: string;
    text_de?: string;
    text_it?: string;
    image?: any;
    images?: (string | File)[] | undefined;
    files?: (string | File | {
        file_name: string;
        res_url: string;
        res_type: 'IMG' | 'VDO' | 'FLE';
    })[] | undefined;
    email_notification?: boolean;
    push_notification?: boolean;
    start_date?: string,
    comment_enabled?: boolean;
    is_newsflash?: boolean;
    like_enabled?: boolean;
    like_name?: string;
    res_url?: string;
    res_type?: string;
    groupsIds: undefined | number[],
    creator_role_type: 1 | 2;
}

export enum CreateMessageBodyRefType {
    USER = "user",
    GROUP = "group",
    COMPANY = "company"
}

/**
 * Create Message body request
 */
export interface CreateMessageBody {
    [key: string]: any;
    title: string;
    text: string;
    optional: boolean;
    ref_id: number | string | undefined;
    ref_ids?: number[] | string[];
    ref_type?: CreateMessageBodyRefType;
}

export interface UserContract {
    id?: number;
    weekly_working_hours?: number;
    vacationIncreasedPercentByHour?: number;
    department?: Department;
    yearly_vacation_days?: number;
    work_rate?: number;
    overtime_hours?: number;
    contract?: string | File;
    contract_expiry_date?: string;
    date_in_report?: string;
    notes?: string;
    is_current?: boolean;
    name?: string;
}

export interface NetworkDaysOffByType {
    id?: number;
    typeOfDayOff?: TypeOfDayOff;
    user?: UserOnlyId;
    inReports?: boolean;
    countAsWorktime?: boolean;
    archived?: boolean;
    numberOfDays?: number;
    expiryDate?: string;
    year?: number;
    userJob?: UserContractSmall;
    canBeBadged?: boolean;
}

/**
 * Body payload for create user request
 */
export interface CreateUserBodyRequest {
    [key: string]: any;
    user_id?: number;
    general: {
        code?: string;
        pic?: string | File;
        role?: number;
        first_name?: string;
        last_name?: string;
        username?: string;
        language: string;
        sex?: 'M' | 'F' | 'X' | 'undefined';
        doj?: string;
        team_visibility?: boolean;
        can_edit_planning?: boolean;
        can_read_only_all_planning?: boolean;
        check_events_overlap?: boolean;
    };
    contact: {
        email?: string;
        phone?: string;
        mobile?: string;
        languages?: string;
        address?: string;
        location?: string;
        zip?: string;
        country?: string;
    };
    about_me: {
        [key: string]: any;
        dept?: string | string[];
        expertise?: string | string[];
        skills?: string | string[];
    };
    educations: UserEducations;
    bio: {
        bio?: string;
    };
    files?: NetworkDynamicFile[];
    job: UserContract;
    image?: string;
    stafftypesIds?: number[];
    groupsIds?: number[];
    inputs?: CreateUserInput[];
}

export interface CreateUserInput {
    fieldId: number;
    floatNumberValue?: number;
    integerNumberValue?: number;
    textValue?: string;
    textareaValue?: string;
    booleanValue?: boolean;
    dateValue?: string;
    datetimeValue?: string;
    timeValue?: string;
}


export interface NetworkDynamicFile extends AbstractDynamicFile {
    expiryDate?: string;
    user?: UserSummary;
    note?: string;
    ignoreExpiration?: string;
    active?: boolean;
}

/**
 * Body payload for create group request
 */
export interface CreateGroupBody {
    name: string;
}

/**
 * Body payload for user assign group request
 */
export interface UserAssignGroupsBody {
    user_id: number;
    selected_groups: string[];
}

/**
 * Body payload for user assign group request
 * One user one group
 */
export interface UserAssignGroupBody {
    user_id: number;
    group_id: string | number;
    action: "add" | "remove";
}

/**
 * Body payload for user assign groups request
 * One user multiple groups
 */
export interface UserAssignGroupsBody {
    user_id: number;
    selected_groups: string[];
}

/**
 * Type for a network event
 */
export interface NetworkReminder {
    id: number;
    minBeforeEventStart: number;
    retreived: boolean;
    sent: boolean;
    sentTo?: number;
    created: string;
    modified?: string;
}
export interface NetworkEvent {
    id?: number;
    reminders?: NetworkReminder[];
    creatorId?: number;
    modifierId?: number;
    startDate: string;
    endDate: string;
    title: string;
    userName?: string;
    description?: string;
    userId?: number;
    groupId?: number;
    isGlobal?: boolean;
    isDraggable?: boolean;
    confirmed?: boolean;
    color?: PlanningColor;
    colorId?: number;
    typeOfDay?: TypeOfDay;
    typeOfDayId?: number;
    typeOfDayOff?: TypeOfDayOff;
    typeOfDayOffId?: number;
    project?: Project;
    projectId?: number;
    staffType?: StaffType;
    poi?: POI;
    poiId?: number;
    timerStart?: string;
    timerStop?: string;
    created?: string;
    modified?: string;
    finish?: EventDataType;
    owner?: PlanningEventOwner;
    userNote?: string;
    totalHours?: number;
    totalHoursOvertime?: number;
    totalHoursBreakTime?: number;
    overtimes?: NetworkOvertime[];
    breakTimes?: NetworkBreakTime[];
    overtimeCount?: number;
    breaktimeCount?: number;
    clocked?: NetworkEventClockedSmall;
    ignoreTimeClock?: boolean;
    active?: boolean;
    ruleId?: number;
    eventLocked?: boolean;
}

export interface NetworkBreakTime {
    id: number;
    title: string;
    startDate: string;
    endDate: string;
    totalHours: number;
    totalSeconds: number;
    isPaid: boolean;
    active: boolean;
    isClocked: boolean;
    clocked?: NetworkBreakTimeClocked;
}

export interface NetworkPlanningPeriodEdit {
    id?: number,
    title: string,
    startDate?: string,
    endDate?: string,
    user?: UserSummary;
    group?: Group;
    rrule?: RRuleOptions;
    finish?: EventDataType;
    isUserCreated?: boolean;
    isConfirmed?: boolean;
    isRefused?: boolean;
}

/**
 * Type for the event confirmation state
 */
export interface NetworkEventConfirmed {
    event: NetworkEvent;
    user: UserSummary;
    confirmed: boolean;
    date: string;
}
export interface NetworkEventImage {
    id: number;
    event: number;
    name?: string;
    image: string;
    active: boolean;
    fullImageUrl: string;
    created: string;
    modified: string;
}

export interface NetworkTemplate extends NetworkEvent {
}
// export interface NetworkTemplate extends NetworkEvent {
//     id?: number;
//     startDate: string;
//     endDate: string;
//     title: string;
//     color?: PlanningColor;
//     typeOfDay?: TypeOfDay;
//     typeOfDayOff?: TypeOfDayOff;
//     poi?: POI;
//     department?: Department;
//     breakTimes?: NetworkBreakTime[];
//     theProject?: Project;
//     sendMessage?: boolean;
//     basePoi?: POI;
//     vehicle?: TypeOfVehicle;
//     distance?: number;
//     ignoreTimeClock?: boolean;
// }

/**
 * Type for a network overtime
 */
export interface NetworkOvertime {
    id?: number;
    userId: number;
    startDate: string;
    endDate: string;
    title?: string;
    eventId?: number;
    isNegative?: boolean;
    isConfirmed?: boolean;
    isRefused?: boolean;
}

/**
 * Type of the planning network settings
 */
export interface NetworkSettings {
    [key: string]: any;
    workingDaysOfWeek?: string;
    startHourOfDay?: string;
    endHourOfDay?: string;
    showAvatars?: boolean;
    colors?: PlanningColor[];
    holidaysCountry?: string;
    subscribedOptionalHolidays?: number[];
    groupAdminWriteAuthorization?: string;
    checkEventsOverlap?: boolean;

    displayLastNameFirst?: boolean;
}

export interface NetworkProjectEvent extends NetworkSimpleEvent {
    userName: string;
    userNote: string;
    project?: Project;
}

/**
 * Type of a network period
 */
export interface NetworkPeriod {
    id?: number,
    title: string,
    startDate: string,
    endDate: string,
    user?: UserSummary,
    group?: Group,
    userId?: number;
    groupId?: number;
    rrule?: string;
    isUserCreated?: boolean;
    isConfirmed?: boolean;
    isRefused?: boolean;
}

/**
 * Type of a network period
 */
export interface NetworkExclusion {
    id?: number,
    title: string,
    startDate: string,
    endDate: string,
    user?: UserSummary,
    group?: Group,
    userId?: number;
    groupId?: number;
    rrule?: string;
    isUserCreated?: boolean;
    isConfirmed?: boolean;
    isRefused?: boolean;
}


/**
 * Type of a network period
 */
export interface NewtworkTypeOfDayOff {
    id?: number;
    title?: string;
    abbreviation?: string;
    inReports?: boolean;
    countAsWorktime?: boolean;
    canBeBadged?: boolean;
    daysPerYear?: number;
    expiryDate?: string;
}

export interface NetworkUserYearlyParams {
    id?: number;
    year: number;
    initial_overtime_hours?: number;
    user: number;
    userJob: number;
}

export interface NetworkMonthlyPlanningRow {
    id: number;
    title: string;
    startDate: string;
    endDate: string;
    color?: string;
    colorHex?: string;
    countNotConfirmed: number;
    userId: number;
    userFirstName: string;
    userLastName: string;
    userImage?: string;
    projectId?: number;
    departmentId?: number;
    typeOfDayId?: number;
    typeOfDayInReports?: boolean;
    typeOfDayOffId?: number;
    typeOfDayOffInReports?: boolean;
    isClocked: boolean;
    hasImages: boolean;
    poiId?: number;
    countBreaktimes: number;
    countOvertimes: number;
    modified: string;
    loading?: boolean;
    ruleId?: number;
    eventLocked: boolean;
}

export interface INetworkPlanning {
    allUsers: boolean;
    rights: number[];
    data: {
        events: DictionaryNumber<DictionaryNumber<NetworkMonthlyPlanningRowPerfAddons[]>>;
        holidays: { startDate: string; repartition: "all_day" | "am" | "pm"; }[];
        lockedDays: LockedDaysByDates;
        contracts: DictionaryNumber<ContractDataPerf[]>;
        availabilities: NetworkPlanningAvailabilities;
    };
}

export interface NetworkMonthlyPlanningRowPerf {
    id: number;
    title: string;
    startDate: string;
    endDate: string;
    color?: string;
    colorHex?: string;
    confirmed: boolean;
    userId: number;
    userFirstName: string;
    userLastName: string;
    projectId?: number;
    departmentId?: number;
    countAsWorktime: boolean;
    inReports: boolean;
    typeOfDayId?: number;
    typeOfDayOffId?: number;
    hasImages: boolean;
    poiId?: number;
    modified: string;
    loading?: boolean;
    ruleId?: number;
    staffTypeId?: number;
    effectiveSec: number;
    effectiveWithIncreasedSec: number;
    totalBreakTimeSec: number;
    overtimeSec: number;
    eventLocked: boolean;
    customerId?: number;
    mandateId?: number;
    missionId?: number;
    ignoreTimeClock?: boolean;
    timeclockStatus: EventTimeclockStatus;
    startTime: string;
    endTime: string;
    isSingleDay: boolean;
    dateKey: string;
}

export interface NetworkMonthlyPlanningRowPerfAddons extends NetworkMonthlyPlanningRowPerf {
}

export type NetworkPlanningAvailabilities = {
    user: {
        id: number,
        username: string;
        email: string;
        image: string;
        role: number,
        first_name: string;
        last_name: string;
        canEdit: boolean | null;
    },
    unavailabilities: NetworkPlanningAvailability[];
    availabilities: NetworkPlanningAvailability[];
}[];

export interface NetworkPlanningAvailability {
    startDate: string,
    endDate: string;
}

export interface NetworkAllHolidays {
    title: string;
    startDate: string;
    endDate: string;
    isPublicHoliday?: boolean;
    isDayOff?: boolean;
    repartition: "all_day" | "am" | "pm";
}

/**
 * Type of a network exclusion
 */
export interface NetworkExclusion {
    id?: number,
    title: string,
    startDate: string,
    endDate: string,
    user?: UserSummary,
    group?: Group,
    userId?: number;
    groupId?: number;
    rrule?: string;
    is_user_created?: boolean;
    is_confirmed?: boolean;
    is_refused?: boolean;
}

/**
 * Type of a network effective period
 */
export interface NetworkEffectivePeriod {
    startDate: string;
    endDate: string;
}


/**
 * Type of network statistics
 */
export interface NetworkStatistics {
    general: {
        nbUsers: number;
        nbGroups: number;
        nbNews: number;
        nbMessages: number;
    };
    planning: {
        nbEvents: number;
        todayEvents: NetworkEvent[];
        startingTodayEvents: NetworkEvent[];
        endingTodayEvents: NetworkEvent[];
    };
    availability: {
        users: User[];
        groups: Group[];
    };
}

/**
 * Type of a body request to create/edit a directory listing
 */
export interface ListingDirectoryBody {
    directory_id?: number;
    link?: string;
    category_indexes?: {
        ci?: number;
        sci?: number;
    };
    opening_hours?: {
        start_time?: string;
        end_time?: string;
    };
    name?: string;
    subtitle?: string;
    sort_order?: number;
    desc?: string;
    email?: string;
    phone?: string;
    address?: string;
    file?: any;
    files?: any[];
    image?: any;
    images?: any[];
    category_id?: number;
    sub_category_id?: number;
    cat_index?: number;
    subcat_index?: number;
    extra_payload?: any;
}

/**
 * Type of a body request to create/edit a listing category
 */
export interface ListingCategoryBody {
    id?: number;
    name?: string;
    image?: any;
    active?: boolean;
    sort_order?: number;
    link?: string;
    external?: boolean;
    icon?: string;
    subcategory?: ListingSubCategoryBody[];
}

export interface CategoryRightsBody {
    categoryId: number;
    groupsIds: number[];
}

/**
 * Type of a body request to create/edit a listing sub category
 */
export interface ListingSubCategoryBody {
    category_id?: number;
    id?: number;
    name?: string;
    image?: any;
    active?: boolean;
    sort_order?: number;
    link?: string;
    external?: boolean;
}

/**
 * Type of a network occupancy rate
 */
export interface NetworkOccupancyRate {
    id?: number;
    typeOfDay: TypeOfDay | undefined;
    poi?: POI;
    basePoi?: POI;
    staffType?: StaffType;
    department?: Department;
    title: string;
    quantity: number;
    frequency: number;
    startDate?: string;
    endDate?: string;
    startHour?: string;
    endHour?: string;
    rrule?: string;
    duringHolidays?: boolean;
    duringVacations?: boolean;
    exclusions?: number[];
}

/**
 * Type of a network occupancy rate exclusion
 */
export interface NetworkOccupancyRateExclusion {
    id?: number;
    startDate?: string;
    endDate?: string;
}

//For Ccnt typed reports
export interface NetworkCcntEventBreaktime {
    dateFrom: string;
    dateTo: string;
}

export interface NetworkCcntEventOvertime {
    dateFrom: string;
    dateTo: string;
    isNegative: boolean;
}

//For Ccnt typed reports
export interface NetworkReportEventBreaktime {
    dateFrom: string;
    dateTo: string;
}

export interface NetworkReportventOvertime {
    dateFrom: string;
    dateTo: string;
    isNegative: boolean;
}

export interface NetworkCcntEvent {
    type: string;
    dateFrom: string;
    dateTo: string;
    overtime: NetworkCcntEventOvertime[];
    breaktime: NetworkCcntEventBreaktime[];
}

export interface NetworkCcnt {
    weeklyPlannedHours: number;
    lastYearsWorkHours: number;
    lastYearsRest: number;
    lastYearsPublicVacation: number;
    lastYearsVacation: number;
    paidVacationDays: number;
    paidPublicHolidayDays: number;
    paidHours: number[];
    paidRestDays: number;
    events: NetworkCcntEvent[];
    company: string;
    employee: string;
    entryDate: string;
    exitDate: string;
    workHoursPerWeek: number;
    workYear: number;
}

export interface NetworkCctSecurityEvent {
    title: string;
    color: string;
    distance: number;
    theProject: string;
    typeOfDay: string;
    typeOfDayOff: string;
    userNote: string;
    vehicleType: number;
    poiAddress: string;
    basePoi: string;
    dateFrom: Moment;
    dateTo: Moment;
    overtime: NetworkReportventOvertime[];
    breaktime: NetworkReportEventBreaktime[];
}


export interface NetworkCctSecurity {
    lastYearsWorkHours: number;
    lastYearsVacation: number;
    events: NetworkCctSecurityEvent[];
    company: string;
    employee: string;
    entryDate: Moment;
    exitDate: Moment;
    workHoursPerWeek: number;
    workYear: number;
    ContractType: string;
    ContractMaxHours: number;
    ContractMinHours: number;
    ContractName: string;
}


//for monthly "normal" reports
export interface NetworkMonthlyReportEventBreaktime {
    dateFrom: Moment;
    dateTo: Moment;
}

export interface NetworkMonthlyReportEventOvertime {
    dateFrom: Moment;
    dateTo: Moment;
    isNegative: boolean;
}

export interface NetworkMonthlyReportEvent {
    dateFrom: Moment;
    dateTo: Moment;
    overtime: MonthlyReportEventOvertime[];
    breaktime: MonthlyReportEventBreaktime[];
    title: string;
    type_of_day?: string;
    type_of_day_off?: string;
    color: string;
    project: string;
    distance: number;
}

export interface NetworkMonthlyReport {
    workRate: number;
    workYear: number;
    dailyPlannedHours: number;
    balanceHours: number;
    balanceOfTypeOfDaysOff: BalanceOfTypeOfDaysOff[];
    events: MonthlyReportEvent[];
    company: string;
    employee: string;
    workHoursPerWeek: number;
    showDayOfWeek: boolean;
    showDistance: boolean;
    showProject: boolean;
    summaryErrors: ReportSummaryError[];
}

export interface NetworkSimpleContract {
    id: number;
    name: string;
    startDate: string;
    endDate: string;
    weeklyWorkingHours: number;
    workRate: number;
}


export interface NetworkSimpleOvertime {
    dateFrom: string;
    dateTo: string;
    isNegative: boolean;
    isConfirmed: boolean;
    isRefused: boolean;
}

export interface NetworkSimpleBreaktime {
    dateFrom: string;
    dateTo: string;
    isPaid: boolean;
}

export interface NetworkSimpleEvent {
    id: number;
    title: string;
    dateFrom: string;
    dateTo: string;
    typeOfDay?: TypeOfDay;
    typeOfDayOff?: TypeOfDayOff;
    overtimes: NetworkSimpleOvertime[];
    breakTimes: NetworkSimpleBreaktime[];
}

/**
 * Type of a occupancy rate body request
 */
export interface OccupancyRateBodyRequest {
    id?: number; //mandatory for edition
    typeOfDay?: number; //mandatory for creation
    staffType?: number;
    poi?: number;
    department?: number;
    title?: string; //mandatory for creation
    quantity?: number; //mandatory for creation
    frequency?: number; //mandatory for creation
    startDate?: string;
    endDate?: string;
    startHour?: string;
    endHour?: string;
    rrule?: string;
    duringHolidays?: boolean;
    duringVacations?: boolean;
    exclusions?: number[];
    poiIds?: number[];
}

export interface LoadClientBodyRequest {
    From: Moment;
    To: Moment;
    MedalId?: string,
    ProductIds?: string[],
    ActivityIds?: string[],
    LanguageIds?: string[],
    CountryIds?: string[],
}

export interface PromotionTranslationBodyRequest {
    lang: string;
    title: string;
    description: string;
    schoolName: string;
}

export interface PromotionClientBodyReques {
    mail: string;
    lang: string;
}

export interface PromotionBodyRequest {
    code: string;
    clients: PromotionClientBodyReques[];
    mailContent: PromotionTranslationBodyRequest[];
}

/**
 * Type for the Network holidays
 */
export interface NetworkHolidays {
    id: number;
    title: string;
    startDate: string;
    endDate: string;
    mandatoryCountries: string;
    optionalCountries: string;
}

/**
 * Type for the network vacations
 */
export interface NetworkVacations {
    id?: number;
    title: string;
    startDate: string;
    endDate: string;
}

/**
 * Type for the network Type Of Vehicle
 */
export interface NetworkTypeOfVehicle {
    id?: number;
    name: string;
    type?: number;
    description?: string;
}

/**
 * Type for the network Majoration
 */
export interface NetworkMajoration {
    id?: number;
    name: string;
    majStart?: string;
    majEnd?: string;
    majDay?: string[];
    majPercent?: number;
    isMajHoliday?: boolean; // Jours fériés = Holidays
    isMajDayOff?: boolean; // Jour chômés = Days Off
}

/**
 * Type for the network Type Of Contract
 */
export interface NetworkTypeOfContract {
    id?: number;
    name: string;
    displayName: string;
    hoursMin?: number;
    hoursMax?: number;
}

export interface NetworkIncreasedHoursByDay {
    day: IncreasedHours;
    percentage: number;
    allDay: boolean;
    // datetime
    ruleStartDate: string;
    ruleEndDate: string;
    // only time (moment(component.props.data.value, [moment.ISO_8601, MOMENT_SHORT_TIME_FORMAT]);)
    startTime?: string;
    endTime?: string;
    // datetime
    created?: string;
    modified?: string;
}

/**
 * Type for a customer relationship management promotion message
 */
export interface CRMPromotion {
    id: number,
    code: number;
    created: string;
}

export interface CRMMessageSMS {
    id: number;
    text: string;
    smsID: number;

}
export interface CRMMessageMail {
    id: number;
    text: string;
    title: string;
    email_id?: string;
}
export interface CRMMessageClient {
    id: number;
    firstName: string;
    lastName: string;
    language: string;
    mail: string;
    mobile: string;
}
export interface CRMMessage {
    id: number;
    created: number;
    clients: CRMMessageClient[];
    mail?: CRMMessageMail;
    sms?: CRMMessageSMS;
    type: 'mail' | 'sms';
}

export interface MessagesToBodyRequest {
    firstName: string;
    lastName: string;
    language: string;
    mail: string;
    mobile: string;
    cid: number;

}
export interface MessagesBodyRequest {
    type: 'sms' | 'mail';
    text: string;
    to: MessagesToBodyRequest[];
}
export interface MailTranslationBodyRequest {
    lang: string;
    title: string;
    description: string;
    schoolName: string;
}
export interface SMSTranslationBodyRequest {
    lang: string;
    message: string;
}
export interface MailBodyRequest extends MessagesBodyRequest {
    translations: MailTranslationBodyRequest[];
}
export interface SMSBodyRequest extends MessagesBodyRequest {
    delay?: string; //only with sms
    links: string[]; //only with sms
    simulate?: boolean; //only with sms
    translations: SMSTranslationBodyRequest[];
}

/**
 * Type for a network user vacations
 */
export interface NetworkUserVacations {
    id?: number;
    title: string;
    startDate: string;
    endDate: string;
    user: UserSummary;
    typeOfVacations?: TypeOfVacations;
}

/**
 * Type for a user vacations body request
 */
export interface UserVacationsBodyRequest {
    id?: number;
    title?: string;
    startDate?: string;
    endDate?: string;
    userId?: number;
    typeOfVacationsId?: number;
}

/**
 * Type for a network user extra vacations
 */
export interface NetworkUserExtraVacations {
    id?: number;
    title: string;
    startDate: string;
    endDate: string;
    user: UserSummary;
    typeOfVacations?: TypeOfVacations;
}

/**
 * Type for a user extra vacations body request
 */
export interface UserExtraVacationsBodyRequest {
    id?: number;
    title?: string;
    startDate?: string;
    endDate?: string;
    userId?: number;
    typeOfVacationsId?: number;
}

/**
 * Type for a user vacations summary
 */
export interface UserVacationsSummary {
    user: UserSummary;
    yearlyVacationDays?: number;
    extraVacationDays?: number;
    availableExtraVacationDays?: number;
    totalVacationDays?: number;
    takenVacationDays?: number;
    remainingVacationDays?: number;
    todayRemainingVacationDays?: number;
}

/**
 * Type for a user row
 */
export interface NetworkUserRow {
    user: UserSummary;
    days: NetworkUserRowDay[];
    maxEvents: number;
}

/**
 * Type for a user row's day
 */
export interface NetworkUserRowDay {
    isOutsideWorkingDays?: boolean;
    isOutsidePeriods?: boolean;
    isUserVacations?: boolean;
    isCompanyVacations?: boolean;
    events?: NetworkEvent[];
    overtimes?: NetworkOvertime[];
}

/**
 * Type for a calendar data body request
 */
export interface CalendarDataBodyRequest {
    fromDate: string;
    toDate: string;
    dataType?: number;
    confirmation?: number;
    displayWorkingTime?: boolean;
    displayDayView?: boolean;
    userIds?: number[];
    groupId?: number[];
}

/**
 * Type for a network cell event
 */
export interface NetworkCellEvent {
    event: NetworkEvent;
    startCell: number;
    endCell: number;
    isStart: boolean;
    isEnd: boolean;
    overlap: number;
}

/**
 * Type for a network cell overtime
 */
export interface NetworkCellOvertime {
    overtime: NetworkOvertime;
    startCell: number;
    endCell: number;
}

/**
 * Type for a network cell day
 */
export interface NetworkCellDay {
    date: string;
    endCell: number;
    overlap: number;
    cellEvents: NetworkCellEvent[];
    cellOvertimes: NetworkCellOvertime[];
}

/**
 * Type for a network calendar user data
 */
export interface NetworkCalendarUserData {
    user: UserSummary;
    cellPeriods: CellIndexes[];
    cellVacations: CellIndexes[];
    cellUserVacations: CellIndexes[];
    days: NetworkCellDay[];
}

/**
 * Type for a network calendar data
 */
export interface NetworkCalendarData {
    calendarUserData: NetworkCalendarUserData[];
    cellsPerRow: number;
    currentStartHour: number;
    currentEndHour: number;
}

//#region NetworkCctSecurityResponse
export interface NetworkCctSecurityInitialHours {
    id: number;
    contractId: number;
    initialOvertimeHours: number;
    userId: number;
    year: number;
}

export interface NetworkCctSecurityInitialDaysOff {
    id: number;
    contractId: number;
    numberOfDays: number;
    typeOfDayOffId: number;
    userId: number;
}


export interface NetworkSimpleEventCctSecurity extends NetworkSimpleEvent {
    poi?: POI;
    basePoi?: POI;
    vehicle?: TypeOfVehicle;
    distance?: number;
    project?: Project;
    userRemarks?: string;
}

export interface NetworkCctSecurityData {
    id: number;
    firstName: string;
    lastName: string;
    events: NetworkSimpleEventCctSecurity[];
    contracts: NetworkSimpleContract[];
}

export interface NetworkCctSecurityResponse {
    data: NetworkCctSecurityData[];
    initialDaysOff: NetworkCctSecurityInitialDaysOff[];
    initialHours: NetworkCctSecurityInitialHours[];
}
//#endregion

//#region UserAvailability
export interface UserAvailabilityEditNetwork {
    id?: number,
    user: UserShort,
    title?: string,
    startTime?: string;
    endTime?: string;
    startDateRule?: string;
    endDateRule?: string;
    rrule?: string;
    isContinue: boolean;
}

export interface UserAvailabilityNetwork extends UserAvailabilityEditNetwork {
    // Override vars
    id: number;
    title: string,
    startTime: string;
    endTime: string;
    startDateRule: string;
    endDateRule: string;

    // New vars
    createdByGroup: boolean;
    created: string;
    modified: string;
    isUserCreated: boolean;
    isConfirmed: boolean;
    isRefused: boolean;
}
//#endregion

export enum ErrorType {

}

export interface GeneralResponse {
    error: boolean;
    message?: string;
    type?: ErrorType;
    errorIds?: number[];
    successIds?: number[];
    data?: any;
}

export interface NetworkErrors<Y = string> {
    reason: string;
    code?: Y;
    ids: number[];
}

export interface NetworkResponse<T, Y = string> {
    error: boolean;
    message?: string;
    type?: ErrorType;
    code?: string;
    errorIds?: number[];
    successIds?: number[];

    errors?: NetworkErrors<Y>[],
    data: T;
}

export interface NetworkUserEffectiveHours extends UserEffectiveHoursAbstract {
    endDate: string;
    startDate: string;
}

export interface NetworkEventClockedDevice {
    active: boolean;
    created: string;
    description: string;
    modified: string;
    name: string;
    os: string;
    uid: string;
    userAgent: string;
    version: string;
    deviceType: string;
    nrStartedEventClocked?: number;
    nrStopppedEventClocked?: number;
    nrManuallySetEventClocked?: number;
}
export interface NetworkEventClocked {
    id: number;
    eventId: number;
    userId: number;
    isConfirmed: boolean;
    isManual: boolean;
    isRefused: boolean;
    created: string;
    description: string;
    modified: string;
    calculated: EventClockedCalculated;
    name: string;
    adminEndDate: string | null;
    adminStartDate: string | null;
    endDate: string | null;
    startDate: string | null;
    manualDescription: string,
    manualEndDate: string | null;
    manualStartDate: string | null;
    planEndDate: string | null;
    planStartDate: string | null;
    startTimeClockDevice?: NetworkEventClockedDevice;
    endTimeClockDevice?: NetworkEventClockedDevice;
    manualTimeClockDevice?: NetworkEventClockedDevice;
    error: boolean;
    color: string;
    breakTimesClocked: NetworkBreakTimeClocked[];
}
export interface NetworkBreakTimeClocked {
    id: number;
    title: string;
    startDate?: string;
    endDate?: string;
    isPaid: boolean;
    totalHours: number;
    totalSeconds: number;
    manualStartDate?: string;
    manualEndDate?: string;
    manualIsPaid?: boolean;
    manualDescription?: string;
    adminStartDate?: string;
    adminEndDate?: string;
    adminIsPaid?: boolean;
    isManual: boolean;
    isConfirmed: boolean;
    isRefused: boolean;
    startTimeClockDevice?: NetworkEventClockedDevice;
    endTimeClockDevice?: NetworkEventClockedDevice;
    manualTimeClockDevice?: NetworkEventClockedDevice;
    created: string;
    modified?: string;
}

export interface NetworkEventWithEventClockedTimeTracking {
    id: number;
    userId: number;
    title: string;
    color: string;
    startDate: string;
    calculated: EventCalculated;
    endDate: string;
    breakTimes: NetworkBreakTime[];
    clocked?: NetworkSmallEventClocked;
    ignoreTimeClock?: boolean;
    typeOfDay?: TypeOfDay;
    typeOfDayOff?: TypeOfDayOff;
    fromTimeClock?: boolean;
    userRemarks?: string;
}

export interface NetworkEventWithEventClocked {
    id: number;
    userId: number;
    title: string;
    color: string;
    startDate: string;
    calculated: EventCalculated;
    endDate: string;
    breakTimes: NetworkBreakTime[];
    clocked?: NetworkSmallEventClocked;
    ignoreTimeClock?: boolean;
    typeOfDay?: TypeOfDay;
    typeOfDayOff?: TypeOfDayOff;
    fromTimeClock?: boolean;
    userRemarks?: string;
}

export interface NetworkSmallEventClocked {
    id: number;
    description: string;
    isConfirmed: boolean;
    isRefused: boolean;
    isManual: boolean;
    startDate: string;
    endDate?: string;
    manualStartDate?: string;
    manualEndDate?: string;
    manualDescription?: string;
    breakTimeDuration: number;
    eventBreakTimeDuration: number;
    created: string;
    modified: string;
    startTimeClockDevice?: NetworkEventClockedDevice;
    endTimeClockDevice?: NetworkEventClockedDevice;
    manualTimeClockDevice?: NetworkEventClockedDevice;
    breakTimesClocked?: NetworkBreakTimeClocked[];
    calculated?: null | EventClockedCalculated;
}

export interface NetworkEventClockedSmall {
    id: number;
    startDate?: string;
    endDate?: string;
    manualStartDate?: string;
    manualEndDate?: string;
    manualDescription?: string;
    adminStartDate?: string;
    adminEndDate?: string;
    planStartDate: string;
    planEndDate: string;
    isManual: boolean;
    isConfirmed: boolean;
    isRefused: boolean;
    breakTimesClocked: NetworkBreakTimeClocked[];
}

export interface NetworkEventClockedSetAdmin {
    adminStartDate?: string;
    adminEndDate?: string;
    breakTimes?: NetworkBreakTime[];
}

export interface NetworkEventClockedsConfirmRejectSetAdmin {
    eventClockedId: number;
    adminStartDate?: string;
    adminEndDate?: string;
}

export interface NetworkEventClockedResponse {
    error: boolean;
    data: any;
    rights: number[];
}

export interface NetworkEventClockedSetManually {
    manualStartDate: string;
    manualEndDate: string;
    manualDescription: string;
}
export interface NetworkSetEventParameters {
    ignoreTimeClock?: boolean;
    typeOfDayId?: number;
    typeOfDayOffId?: number;
    templateId?: number;
}

export type NetworkUserHoursSummaries = UserHoursSummary[];

export type NetworkUserHoursSummariesWithContractDetails = UserHoursSummaryWithContractDetails[];

export interface NetworkUserImport {
    uuid: string;
    firstname: string;
    lastname: string;
    email: string;
    username: string;
    phone?: string;
    dob?: string;
    code?: string;
    contractName?: string;
    contractRate?: number;
    contractWeeklyHours?: number;
    contractStart?: string;
    contractEnd?: string;
    contractInit?: number;
}

export interface NetworkUserImportResponse {
    sameUsername: boolean;
    sameEmail: boolean;
    user: NetworkUserImport;
}

export interface NetworkPlanningStatisticsV2 {
    user: {
        id: number,
        fullName: string;
    },
    workRate: number,
    weeklyWorkingHours: number,
    dailyWorkingHours: number,
    monthTotals: {
        year: number,
        month: number,
        initialHours: number,
        effectiveHours: number,
        todo: number,
        toReport: number;
    }[];
}

export enum StatusType {
    NONE = 'none',
    PENDING = 'pending',
    FULFILLED = 'fulfilled',
    REJECTED = 'rejected',
}

export type Loaded<T> = ({
    status: StatusType.NONE | StatusType.PENDING | StatusType.REJECTED,
    data?: T;
    updatedAt?: string;
} | {
    status: StatusType.FULFILLED,
    data: T;
    updatedAt: string;
});


export interface EventCanBeCopiedNetwork {
    endDate: string;
    startDate: string;
    userId: number;
    eventId: number;
    errorType: EventCopyErrorType;
    errorValues: EventCopyErrorCodes[];
}

export interface EventCanBeCreatedOrEditedNetwork {
    endDate: string;
    startDate: string;
    userId: number;
    eventId?: number;
    errorType: EventCopyErrorType;
    errorValues: EventCopyErrorCodes[];
}
// export interface EventCanBeCopiedUserNetwork {
//     copyStatut: {
//         tooManyHumans: boolean;
//         notDuringHolidays: boolean;
//         notDuringVacations: boolean;
//         notInPeriods: boolean;
//         tooManyHours: boolean;
//         tooManyHoursInEvent: boolean;
//     },
//     copyOptions: EventCanBeCopiedOptions[];
//     startDate: string;
//     endDate: string;
//     userId: number;
//     periods: {
//         startDate: string;
//         endDate: string;
//         missionRuleId: number;
//     }[]
// }

// export interface RubricNetwork {
//     id: number;
//     title: string;
//     code: string;
//     description: string;
//     active: boolean;
// }

// export interface AnalyticPlanNetwork {
//     id: number;
//     title: string;
//     code: string;
//     description: string;
//     unit: string;
//     active: boolean;
// }

// export interface AccountingCominationNetwork {
//     id: number;
//     title: string;
//     default: boolean;
//     rubric: RubricNetwork;
//     analyticPlan: AnalyticPlanNetwork;
// }
export interface LockedDays {
    [day: string]: boolean;
    wholeMonth: boolean;
}
export interface LockedDaysByMonths {
    [month: string]: LockedDays;

}
export interface LockedDaysByYears {
    [year: string]: LockedDaysByMonths;

}

export type LockedDaysOrMonth = number[][];

export type LockedDaysByDates = DictionaryString<number[]>;

export interface LockedDaysByUsersByYearsByMonths {
    [user_id: string]: LockedDaysByYears;
}

export interface IUserExportHoursBase {
    isAuxiliary: boolean;
}

export interface IUserExportHoursHotela extends IUserExportHoursBase {
    userData: IUserExportHoursHotelaUserData;
}

export interface IUserExportHoursHotelaUserData {
    id: number;
    dayOff: string;
    publicDays: string;
    vacation: string;
    unpaidDays: string;
    paidDayOff: string;
    paidPublicDays: string;
    paidVacation: string;
    militaryDays: string;
    dayCount: string;
    hourCount: string;
    regularOvertime: string;
    irregularOvertime: string;
    extraHoursBalance: string;
    extraDaysBalance: string;
    extraHolidaysDaysBalance: string;
    extraPublicDaysBalance: string;
    nightBonus10: string;
    nightBonus25: string;
    periods: IUserExportHoursHotelaPeriod[];
    created: string;
    modified: string;
}

export interface IUserExportHoursHotelaPeriod {
    id: number;
    periodType: string;
    startDate: string;
    endDate: string;
    rate: number;
    created: string;
    modified: string;
}

export interface NetworkSyncableUsers {
    usersExportable: number[];
    waitingTimeClockValidation: number[];
    waitingAggregatedValidation: number[];
    usersNotLinked: number[];
    usersWithoutData: number[];
    pendingRetry: number[];
}

export interface GlobalOfficeResumeHours {
    totalHours: number;
    totalTimeOff: number;
}

export interface IUserExportHoursGlobalOffice extends IUserExportHoursBase {
    userData: IUserExportHoursGlobalOfficeUserData;
}

export interface IUserExportHoursGlobalOfficeUserData {
    [customerId: string]: IUserExportHoursGlobalOfficeUserDataCustomer | IUserExportHoursGlobalOfficeUserDataCustomerHours;
    orphelin: IUserExportHoursGlobalOfficeUserDataCustomerHours;
}
export interface IUserExportHoursGlobalOfficeUserDataCustomer {
    [mandateId: string]: IUserExportHoursGlobalOfficeUserDataCustomerHours;
}
export interface IUserExportHoursGlobalOfficeUserDataCustomerHours {
    totalHours?: number;
    totalTimeOff?: number;
}

export interface SunkhronosNews {
    id: number;
    title: string;
    content: string;
    newsType: number;
    companyTypes: number[];
    newsTags?: SunkhronosNewsTag[];
    image: string;
    startDate: string;
    version: string;
}

export interface SunkhronosNewsTag {
    id: number;
    title: string;
    color: string;
}