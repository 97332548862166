import { Alert, Avatar, Badge, Button, Checkbox, Col, Divider, Drawer, Dropdown, Empty, List, Modal, Popover, Row, Select, Space, Switch, Tabs } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { cloneDeep } from 'lodash';
import toInteger from 'lodash/toInteger';
import moment from 'moment';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import { Privileges } from '../../privileges';
import { Rules } from '../../rbacRules';
import { changeStaffTypes } from '../../store/actions/configurations';
import { changeGroups, changeUsers } from '../../store/actions/teamManagement';
import '../../styles/teamManagement.css';
import { groupsColumns, RED_COLOR } from '../../utils/constants';
import Excel, { IBasicTableRow } from '../../utils/exports/Excel';
import getFormat from '../../utils/Lang';
import Network from '../../utils/network';
import { FullUserProps, withFullName } from '../../utils/objects/withFullName';
import { usersColumns } from '../../utils/tableUtils';
import { Group, IIntegrationsType, ImportRows, RouterProps, StaffType, User, UserField } from '../../utils/types/generalTypes';
import { ApplicationState, StoreDispatch } from '../../utils/types/storeTypes';
import { alert, checkPrivilege, showNotification } from '../../utils/utils';
import { IntlProps } from '../app/LanguageProvider';
import FAIcon from '../common/FAIcon';
import CircleButton from '../common/fields/circleButton';
import DeleteButton from '../common/fields/deleteButton';
import InputField, { InputFieldOnChangeEvent } from '../common/fields/inputField';
import SpeedDial from '../common/fields/speedDial';
import Can from '../common/general/can';
import Card from '../common/general/card';
import SpaceContent from '../common/general/spaceContent';
import VirtualTable from '../common/general/virtualTable';
import Container from '../common/navigations/container';
import { UsersManagementRef } from '../integrations/tabs/export';
import UsersManagement from '../integrations/tabs/usersManagement';
import UserLogedIn from '../mobileApp/tabs/components/userLogedIn';
import ImportUsers from './import/importUsers';
import StaffTypeShowForm from './staffType/staffTypeShowForm';
import { exportUsersFiles } from './utils/utils';

type ReduxProps = ConnectedProps<typeof connector>;
interface Props extends RouterProps, ReduxProps, IntlProps, FullUserProps {
}

interface State {
    usersArchived: User[] | undefined;
    searchedUsers: User[] | undefined;
    searchedGroups: Group[] | undefined;
    usersLoading: boolean;
    groupsLoading: boolean;
    selectedUsers: number[];
    toDeleteUsers?: number[];
    selectedStaffType: number[];
    editGroup: boolean;
    nameGroupEdit: string;
    nameGroupEditError: string;
    loadingGroupEdit: boolean;
    selectedGroupUsers: number[];
    isShowStaffType: boolean;
    // staffTypeShow?: StaffType;
    isEditStaffType: boolean;
    staffType?: StaffType;
    staffTypeEditName: boolean;
    displayAlert: boolean;
    alertMessage: string;
    alertType: 'success' | 'info' | 'warning' | 'error';
    archived: boolean;
    notAlreadyConnected: boolean;
    noneCommunication: boolean;
    showUserLoggedInModal: boolean;
    loggedInType: 'all' | 'mobile' | 'admin' | 'timeclock' | 'SwissSkiSchool' | 'UNKNOWN';

    loadingDeleteUsers: boolean;
    loadingArchiveUsers: boolean;
    loadingDearchiveUsers: boolean;
    loadingDisableReport: boolean;
    loadingActivateReport: boolean;
    confirmDeletion: boolean;

    displayImportUsers: boolean;
    importUsersData: ImportRows;


    showLinkedUsersModal: boolean;
    selectedIntegration: IIntegrationsType | undefined;
}

/**
 * Page with the users list
 */
class TeamManagement extends React.Component<Props, State> {
    ref = React.createRef<UsersManagementRef>();

    constructor(props: Props) {
        super(props);
        const { integrations } = props;

        this.state = {
            usersArchived: undefined,
            searchedUsers: undefined,
            searchedGroups: undefined,
            usersLoading: false,
            groupsLoading: false,
            selectedUsers: [],
            selectedStaffType: [],
            editGroup: false,
            nameGroupEdit: '',
            nameGroupEditError: '',
            loadingGroupEdit: false,
            selectedGroupUsers: [],
            isShowStaffType: false,
            isEditStaffType: false,
            displayAlert: false,
            alertMessage: '',
            alertType: 'success',
            archived: false,
            notAlreadyConnected: false,
            noneCommunication: false,
            staffTypeEditName: false,
            showUserLoggedInModal: false,
            loggedInType: 'admin',

            loadingDeleteUsers: false,
            loadingArchiveUsers: false,
            loadingDearchiveUsers: false,
            loadingDisableReport: false,
            loadingActivateReport: false,
            toDeleteUsers: undefined,
            confirmDeletion: false,

            displayImportUsers: false,
            importUsersData: [],
            showLinkedUsersModal: false,
            selectedIntegration: integrations?.hotelaEnabled ? 'hotela' : integrations?.globalOfficeEnabled ? 'globaloffice' : integrations?.officeMakerEnabled ? 'officemaker' : undefined
        };
    }

    componentDidMount() {
        //display alert message if needed
        if (this.props.location && this.props.location.state && this.props.location.state.successMessage) {
            alert(this.props.location.state.successMessage, "success");
            this.props.history.replace({ state: undefined });
        }
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        if (this.state.archived && !this.state.usersLoading && this.state.archived !== prevState.archived) {
            this.setState({ usersLoading: true });
            Network.getAllArchivedUsers().then(
                response => {
                    this.setState({ usersArchived: response, searchedUsers: undefined, selectedUsers: [], usersLoading: false });
                },
                () => this.setState({ usersArchived: undefined, searchedUsers: undefined, selectedUsers: [], usersLoading: false, }),
            );
        }
    }

    usersOnRow = (record: User) => ({
        onClick: () => this.props.history.push(`/${this.props.match.params.lang}/team-management/user-details/informations?id=${record.id}`)
    });

    groupsOnRow = (record: any) => ({
        onClick: () => this.props.history.push(`/${this.props.match.params.lang}/team-management/group-details/informations?id=${record.id}`)
    });

    goTo = (link: string) => this.props.history.push(link);

    onChangeTab = (tab: string): void => this.props.history.replace(`/${this.props.match.params.lang}/team-management/list/${tab}`);

    handleSearchUsers = (event: InputFieldOnChangeEvent) => {
        const { archived } = this.state;
        const { getFullName } = this.props;
        const search = event.target.value;
        if (search.length === 0) {
            this.setState({ searchedUsers: undefined });
        } else {
            let searchedUsers: (User[] | undefined) = undefined;
            if (archived) {
                searchedUsers = this.state.usersArchived?.filter(u => getFullName(u).toLowerCase().indexOf(search.toLowerCase()) >= 0);
            } else {
                searchedUsers = this.props.users.filter(u => getFullName(u).toLowerCase().indexOf(search.toLowerCase()) >= 0);
            }
            this.setState({ searchedUsers });
        }
    };

    handleSubmitGroup = () => {
        const { nameGroupEdit, selectedGroupUsers } = this.state;
        const { intl } = this.props;
        if (nameGroupEdit.length === 0) {
            this.setState({ nameGroupEditError: intl.formatMessage({ defaultMessage: 'The name of the group is required' }), loadingGroupEdit: false });
            return;
        }
        this.setState({ loadingGroupEdit: true });
        Network.createGroup(nameGroupEdit, selectedGroupUsers).then(
            (response: Group) => {
                const { groups } = this.props;
                groups?.push(response);
                this.props.changeGroups!(groups ? [...groups] : []);
                this.setState({ loadingGroupEdit: false, editGroup: false, nameGroupEdit: '', nameGroupEditError: '', selectedGroupUsers: [] });
                showNotification(intl.formatMessage({ defaultMessage: 'The group has been successfully created' }), "success");
            },
            error => {
                this.setState({ loadingGroupEdit: false });
                if (error.message === "A group with the same name already exists") alert(intl.formatMessage({ defaultMessage: 'A group with the same name already exists' }), "error");
                else alert(intl.formatMessage({ defaultMessage: 'An error occurred while creating the group' }), "warning");
            }
        );
    };

    handleSearchGroups = (event: InputFieldOnChangeEvent) => {
        const search = event.target.value;
        if (search.length === 0) {
            this.setState({ searchedUsers: undefined });
        } else {
            const searchedGroups = this.props.groups?.filter(g => g.name!.toLowerCase().indexOf(search.toLowerCase()) >= 0);
            this.setState({ searchedGroups });
        }
    };

    openDeleteUsersModal = () => {
        this.setState({ toDeleteUsers: this.state.selectedUsers, confirmDeletion: false });
    };

    deleteUsers = () => {
        const { intl } = this.props;
        const { toDeleteUsers, confirmDeletion } = this.state;
        if (confirmDeletion && toDeleteUsers !== undefined && toDeleteUsers.length > 0) {
            this.setState({ loadingDeleteUsers: true });
            Network.deleteUsers(toDeleteUsers).then(
                (response) => {
                    const successIds = response.successIds;
                    if (response.error) {
                        if ((successIds === undefined || successIds.length === 0)) {
                            showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while deleting the user' }), "warning");
                        } else {
                            showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while deleting the user' }), "error");
                        }
                    } else {
                        showNotification(intl.formatMessage({ defaultMessage: 'The user has been successfully deleted' }), "success");
                    }

                    if (successIds && successIds.length > 0) {
                        this.props.changeUsers(this.props.users.filter(user => !successIds.some(id => id === user.id)));
                    }
                },
                () => showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while deleting the user' }), "error"),
            ).finally(() => this.setState({ loadingDeleteUsers: false, selectedUsers: [], toDeleteUsers: undefined, confirmDeletion: false }));
        }

    };

    activateUsers = () => {
        const { intl } = this.props;
        this.setState({ loadingArchiveUsers: true });
        Network.activateUsers(this.state.selectedUsers).then(
            (response) => {
                const successIds = response.successIds;
                if (response.error) {
                    if ((successIds === undefined || successIds.length === 0)) {
                        showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while activating some users' }), "warning");
                    } else {
                        showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while activating some users' }), "error");
                    }
                } else {
                    showNotification(intl.formatMessage({ defaultMessage: 'The users have been successfully activated' }), "success");
                }

                if (successIds && successIds.length > 0) {
                    this.props.changeUsers(cloneDeep(this.props.users).concat(response.data));
                    this.setState(prevState => ({ usersArchived: prevState.usersArchived?.filter(user => !successIds.some(u => u === user.id)) }));
                }
            },
            () => alert(intl.formatMessage({ defaultMessage: 'An error occurred while activating some users' }), "warning"),
        ).finally(() => this.setState({ loadingArchiveUsers: false, selectedUsers: [] }));
    };

    deactivateUsers = () => {
        const { intl } = this.props;
        this.setState({ loadingDearchiveUsers: true });
        Network.deactivateUsers(this.state.selectedUsers).then(
            (response) => {
                const successIds = response.successIds;
                if (response.error) {
                    if ((successIds === undefined || successIds.length === 0)) {
                        showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while archiving some users' }), "warning");
                    } else {
                        showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while archiving some users' }), "warning");
                    }
                } else {
                    showNotification(intl.formatMessage({ defaultMessage: 'The users have been successfully acthived' }), "success");
                }

                if (successIds && successIds.length > 0) {
                    this.props.changeUsers(this.props.users.filter(user => !successIds.some(id => id === user.id)));
                    this.setState(prevState => ({ usersArchived: prevState.usersArchived ? prevState.usersArchived.concat(response.data) : response.data }));
                }
            },
            () => showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while archiving some users' }), "warning"),
        ).finally(() => this.setState({ loadingDearchiveUsers: false, selectedUsers: [] }));
    };

    activateReports = () => {
        const { intl } = this.props;
        this.setState({ loadingActivateReport: true });
        Network.activateReports(this.state.selectedUsers).then(
            (response) => {
                const successIds = response.successIds;
                if (response.error) {
                    if ((successIds === undefined || successIds.length === 0)) {
                        showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while activating some reports' }), "warning");
                    } else {
                        showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while activating the reports' }), "error");
                    }
                } else {
                    showNotification(intl.formatMessage({ defaultMessage: 'The reports have been successfully activated' }), "success");
                }

                if (successIds && successIds.length > 0) {
                    this.props.changeUsers(this.props.users.map(user => {
                        if (successIds.some(id => id === user.id)) {
                            return {
                                ...user,
                                report: true,
                            };
                        }
                        return user;
                    }));
                }
            },
            () => showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while activating the reports' }), "error"),
        ).finally(() => this.setState({ loadingActivateReport: false, selectedUsers: [] }));
    };

    deactivateReports = () => {
        const { intl } = this.props;
        this.setState({ loadingDisableReport: true });
        Network.deactivateReports(this.state.selectedUsers).then(
            (response) => {
                const successIds = response.successIds;
                if (response.error) {
                    if ((successIds === undefined || successIds.length === 0)) {
                        showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while deactivating some reports' }), "warning");
                    } else {
                        showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while deactivating the reports' }), "error");
                    }
                } else {
                    showNotification(intl.formatMessage({ defaultMessage: 'The reports have been successfully deactivated' }), "success");
                }

                if (successIds && successIds.length > 0) {
                    this.props.changeUsers(this.props.users.map(user => {
                        if (successIds.some(id => id === user.id)) {
                            return {
                                ...user,
                                report: false,
                            };
                        }
                        return user;
                    }));
                }
            },
            () => showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while deactivating the reports' }), "error"),
        ).finally(() => this.setState({ loadingDisableReport: false, selectedUsers: [] }));
    };

    onExportUserFiles = () => {
        const { intl, company } = this.props;
        const users: typeof this.props.users = [...this.props.users, ...(this.state.usersArchived ?? [])].filter(u => this.state.selectedUsers.includes(u.id));

        exportUsersFiles(intl, users, company);
    };

    exportUsers = () => {
        const { intl } = this.props;
        const users: typeof this.props.users = [...this.props.users, ...(this.state.usersArchived ?? [])].filter(u => this.state.selectedUsers.includes(u.id));
        const sheet = new Excel(`${intl.formatMessage({ defaultMessage: 'Users' })}_${moment().format('DD-MM-YYYY')}.xlsx`);
        const rowTemplates = Excel.CreateRowTemplate<User>([
            {
                name: intl.formatMessage({ defaultMessage: 'Active' }),
                width: 5,
                data: (u) => u.active ? intl.formatMessage({ defaultMessage: 'Yes' }) : intl.formatMessage({ defaultMessage: 'No' }),
                onRender: (row, cell, u) => cell.fill = { fgColor: { argb: u.active ? '91ff95' : 'ff7373' }, pattern: 'solid', type: 'pattern' }
            },
            {
                name: intl.formatMessage({ defaultMessage: 'First name' }),
                data: (u) => u.first_name
            },
            {
                name: intl.formatMessage({ defaultMessage: 'Last name' }),
                width: 25,
                data: (u) => u.last_name
            },
            {
                name: intl.formatMessage({ defaultMessage: 'Email' }),
                width: 30,
                data: (u) => u.email
            },
            {
                name: intl.formatMessage({ defaultMessage: 'Username' }),
                data: (u) => u.username
            },
            {
                name: intl.formatMessage({ defaultMessage: 'Birthdate' }),
                width: 15,
                data: (u) => u.doj ? moment(u.doj).format(getFormat('DATE')) : ''
            },
            {
                name: intl.formatMessage({ defaultMessage: 'Address' }),
                width: 30,
                data: (u) => u.contact?.address ?? ''
            },
            {
                name: intl.formatMessage({ defaultMessage: 'City' }),
                data: (u) => u.contact?.location ?? ''
            },
            {
                name: intl.formatMessage({ defaultMessage: 'Role' }),
                width: 15,
                data: (u) => u.role === 1 ? intl.formatMessage({ defaultMessage: 'User' }) : intl.formatMessage({ defaultMessage: 'Administrator' }),
            },
            {
                name: intl.formatMessage({ defaultMessage: 'Groups' }),
                width: 60,
                data: (u) => u.group_users?.map(group => group.group_name).join(', ') ?? '',
            },
            {
                name: intl.formatMessage({ defaultMessage: 'Creation date' }),
                width: 30,
                data: (u) => moment(u.created).format(getFormat('DATE')),
            },
            {
                name: intl.formatMessage({ defaultMessage: 'Last modification date' }),
                width: 30,
                data: (u) => moment(u.modified).format(getFormat('DATE')),
            },
            ...(
                this.props.company?.userFields
                    ? this.props.company?.userFields?.filter(f => !['VDO', 'FLE', 'IMG'].includes(f.type)).map(field => ({
                        name: field.name,
                        data: (u) => this.getFieldValueForUser(u, field) ?? ''
                    })) as IBasicTableRow<User>
                    : []
            )
        ]);
        sheet.createBasicSheetTab(intl.formatMessage({ defaultMessage: 'Users' }), rowTemplates, users);
        console.log("Users", users);
        sheet.downloadSpreadsheet();
    };

    getFieldValueForUser = (user: User, field: UserField) => {
        const { intl } = this.props;
        const userValue = user.inputs?.find(v => v.fieldId === field.id);

        if (userValue) {
            switch (field.type) {
                case 'BOOL':
                    return userValue.booleanValue ? intl.formatMessage({ defaultMessage: 'Yes' }) : intl.formatMessage({ defaultMessage: 'No' });
                case 'CHAR':
                    return userValue.textValue;
                case 'TEXT':
                    return userValue.textareaValue;
                case 'DATE':
                    return userValue.dateValue ? moment(userValue.dateValue, "YYYY-MM-DD").format(getFormat('DATE')) : '';
                case 'TIME':
                    return userValue.timeValue;
                case 'DATETIME':
                    return userValue.datetimeValue ? moment(userValue.datetimeValue).format(getFormat('DATE_AND_TIME')) : '';
                case 'FLOAT':
                    return userValue.floatNumberValue ?? '';
                case 'INT':
                    return userValue.integerNumberValue ?? '';
            }
        } else {
            switch (field.type) {
                case 'BOOL':
                    return field.defaultBooleanValue ? intl.formatMessage({ defaultMessage: 'Yes' }) : intl.formatMessage({ defaultMessage: 'No' });
                case 'CHAR':
                    return field.defaultTextValue;
                case 'TEXT':
                    return field.defaultTextareaValue;
                case 'DATE':
                    return field.defaultDateValue ? moment(field.defaultDateValue, "YYYY-MM-DD").format(getFormat('DATE')) : '';
                case 'TIME':
                    return field.defaultTimeValue;
                case 'DATETIME':
                    return field.defaultDatetimeValue ? moment(field.defaultDatetimeValue).format(getFormat('DATE_AND_TIME')) : '';
                case 'FLOAT':
                    return field.defaultFloatNumberValue?.toString();
                case 'INT':
                    return field.defaultIntegerNumberValue?.toString();
            }
        }
    };

    onChangeSelectedUsers = (keys: React.Key[]) => this.setState({ selectedUsers: keys as number[] });

    onChangeSelectedStaffType = (keys: React.Key[]) => this.setState({ selectedStaffType: keys as number[] });

    staffTypeOnRow = (record: StaffType) => ({
        onClick: () => this.setState({ isShowStaffType: true, staffType: record, staffTypeEditName: false })
    });

    closeStaffTypeShowDrawer = () => {
        this.setState({ isShowStaffType: false, staffType: undefined, displayAlert: false, staffTypeEditName: false });
    };

    deleteStaffType = (staffType?: StaffType, staffTypesIds?: number[]) => {
        const { intl } = this.props;
        if (staffType && staffType.id !== undefined) {
            Network.deleteStaffType([staffType.id]).then(
                () => {
                    let staffTypes = cloneDeep(this.props.staffTypes.data);
                    staffTypes = staffTypes?.filter(p => p.id !== staffType.id);
                    this.props.changeStaffTypes(staffTypes ?? []);
                    showNotification(intl.formatMessage({ defaultMessage: 'The ability {name} has been successfully deleted' }, { name: staffType.name }), "success");
                },
                () => {
                    showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while deleting the ability {name}' }, { name: staffType.name }), "error");
                },
            );
        } else if (staffTypesIds !== undefined && staffTypesIds.length > 0) {
            Network.deleteStaffType(staffTypesIds).then(
                () => {
                    let staffTypes = cloneDeep(this.props.staffTypes.data);
                    staffTypes = staffTypes?.filter(p => staffTypesIds.find(st => st === p.id) === undefined);
                    this.props.changeStaffTypes(staffTypes ?? []);
                    showNotification(intl.formatMessage({ defaultMessage: 'The abilities have been successfully deleted' }), "success");
                },
                () => {
                    showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while deleting the availabilities' }), "error");
                },
            );
        } else {
            showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while deletion' }), "warning");
        }
    };

    refreshIntegrationUsers = () => {
        this.ref.current?.refresh();
    };

    updateStaffType = () => {
        const { intl } = this.props;
        const staffType = { ...this.state.staffType };
        if (staffType) {
            if (staffType.name == null || staffType.name.length == 0) {
                showNotification(intl.formatMessage({ defaultMessage: 'Please give a name to the ability' }), "error");
                return;
            }
            Network.updateStaffType(staffType).then(
                (response: StaffType) => {
                    let staffTypes = cloneDeep(this.props.staffTypes.data);

                    let alertMessage = '';
                    if (staffType.id === undefined) {
                        alertMessage = intl.formatMessage({ defaultMessage: 'The ability has been successfully created' });
                        if (staffTypes && staffTypes.length > 0) {
                            staffTypes.unshift(response);
                        } else {
                            staffTypes = [response];
                        }
                    } else {
                        alertMessage = intl.formatMessage({ defaultMessage: 'The ability has been successfully updated' });
                        staffTypes = staffTypes?.map(st => {
                            if (st.id === response.id) {
                                return response;
                            } else {
                                return st;
                            }
                        });

                    }
                    this.setState({ staffType: response, isEditStaffType: false, alertMessage, alertType: 'success', displayAlert: true, staffTypeEditName: false });

                    this.props.changeStaffTypes(staffTypes ?? []);
                },
                () => {
                    if (staffType.id !== undefined) {
                        const alertMessage = intl.formatMessage({ defaultMessage: 'An error occurred while updating the ability' });
                        this.setState({ isEditStaffType: false, staffType: undefined, alertMessage, alertType: 'error', displayAlert: true });
                    } else {
                        showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while creating the ability' }), 'error');
                        this.setState({ isEditStaffType: false, staffType: undefined, isShowStaffType: false });
                    }
                },
            );
        }
    };

    closeImportAndRefresh = (refresh?: boolean) => {
        this.setState({ displayImportUsers: false });
        if (refresh)
            this.refreshUsers();    // ! TODO ARNAUD Changer cela, juste ajouter les utilisateurs importés à la liste d'utilisateurs au front
    };

    refreshUsers = () => {
        Network.getAllUsers().then(
            response => {
                this.props.changeUsers!(response);
                this.setState({ usersLoading: false });
            },
            () => this.setState({ usersLoading: false }),
        );
    };
    render() {
        const { intl, integrations, isSmartphone, getFullName } = this.props;
        const { toDeleteUsers, confirmDeletion, archived, notAlreadyConnected, noneCommunication, loggedInType, loadingActivateReport, loadingArchiveUsers, loadingDearchiveUsers, loadingDeleteUsers, loadingDisableReport, selectedUsers } = this.state;
        const isCrm = checkPrivilege(Privileges.CRM.Visit, this.props.currentUser);
        const staffTypes = this.props.staffTypes.data;

        const instructorsColumns: ColumnProps<User>[] = [
            {
                title: <FormattedMessage defaultMessage={'Image'} />,
                dataIndex: 'image',
                key: 'image',
                fixed: true,
                render: (link: string, record) => {
                    const alreadyConnected = !record.username.includes("@");
                    const hasMobile = record.contact?.mobile !== undefined && record.contact?.mobile.length > 0;
                    return (
                        <div className="both-center">
                            <Avatar size="large" src={link} icon={
                                <FAIcon prefix='fad' name='user' title={alreadyConnected ? intl.formatMessage({ defaultMessage: 'Already connected to the mobile application' }) : hasMobile ? intl.formatMessage({ defaultMessage: 'Not yet connected to the mobile application' }) : intl.formatMessage({ defaultMessage: 'No means of communication' })} />
                            } style={
                                {
                                    backgroundColor: 'var(--primary-color)',
                                    border: alreadyConnected ? '3px solid #4caf50' : hasMobile ? '3px solid #ffee58' : '3px solid #f44336'
                                }
                            } />
                        </div>
                    );
                },
                width: '80px',
            },
            {
                title: <FormattedMessage defaultMessage={'Last name'} />,
                dataIndex: 'last_name',
                key: 'last_name',
                fixed: true,
                sorter: (a: User, b: User) => a.last_name.localeCompare(b.last_name),
                // defaultSortOrder: 'ascend',
            },
            {
                title: <FormattedMessage defaultMessage={'First name'} />,
                dataIndex: 'first_name',
                key: 'first_name',
                sorter: (a: User, b: User) => a.first_name.localeCompare(b.first_name),
            },
            {
                title: <FormattedMessage defaultMessage={'Mobile'} />,
                key: 'mobile',
                render: (_: any, record) => {
                    return record.contact?.mobile;
                },
            },
            {
                title: <FormattedMessage defaultMessage={'Email'} />,
                dataIndex: 'email',
                key: 'email',
                sorter: (a: User, b: User) => a.email.localeCompare(b.email)
            },
            {
                title: <FormattedMessage defaultMessage={'Role'} />,
                dataIndex: 'role',
                key: 'role',
                render: (r: number) => r === 1 ? <FormattedMessage defaultMessage={'Inst.'} description={'Instructor short'} /> : <FormattedMessage defaultMessage={'Admin'} description={'Admin short'} />,
                filters: [
                    {
                        text: <FormattedMessage defaultMessage={'Instructor'} />,
                        value: "1",
                    },
                    {
                        text: <FormattedMessage defaultMessage={'Administrator'} />,
                        value: "2",
                    }
                ],
                filterMultiple: false,
                onFilter: (value: string | number | boolean, record: User) => record.role === toInteger(value),
                width: '80px',
            },
            {
                title: <FormattedMessage defaultMessage={'Username'} />,
                dataIndex: 'username',
                key: 'username',
                sorter: (a: User, b: User) => a.username.localeCompare(b.username),
            }
        ];

        let parsedUsers = this.props.users;
        parsedUsers = parsedUsers.filter(u => u.visible !== false);
        if (this.state.searchedUsers) {
            parsedUsers = this.state.searchedUsers;
            parsedUsers = parsedUsers.filter(u => u.visible !== false);
            if (notAlreadyConnected && isCrm) parsedUsers = parsedUsers.filter(u => u.username.includes("@"));
        } else if (archived) {
            parsedUsers = this.state.usersArchived ? this.state.usersArchived : [];
            if (notAlreadyConnected && isCrm) parsedUsers = parsedUsers.filter(u => u.username.includes("@"));
        } else if (notAlreadyConnected) {
            if (notAlreadyConnected && isCrm) parsedUsers = parsedUsers.filter(u => u.username.includes("@"));
        }
        if (this.state.searchedUsers) {
            parsedUsers = this.state.searchedUsers;
            parsedUsers = parsedUsers.filter(u => u.visible !== false);
            if (noneCommunication && isCrm) parsedUsers = parsedUsers.filter(u => u.username.includes("@") && (u.contact?.mobile === undefined || u.contact?.mobile.length === 0));
        } else if (archived) {
            parsedUsers = this.state.usersArchived ? this.state.usersArchived : [];
            if (noneCommunication && isCrm) parsedUsers = parsedUsers.filter(u => u.username.includes("@") && (u.contact?.mobile === undefined || u.contact?.mobile.length === 0));
        } else if (noneCommunication) {
            if (noneCommunication && isCrm) parsedUsers = parsedUsers.filter(u => u.username.includes("@") && (u.contact?.mobile === undefined || u.contact?.mobile.length === 0));
        }
        let tableHeight = this.props.height - 277;
        if (tableHeight < 250) tableHeight = 250;

        const headerButtons = [
            <CircleButton
                small
                key={`teamManagement-headerButton-assign`}
                className="__card-button-space"
                disabled={this.state.selectedStaffType === undefined || this.state.selectedStaffType.length === 0}
                icon={<FAIcon prefix='fad' name='grid-2-plus' />}
                title={intl.formatMessage({ defaultMessage: 'Assign' })}
                onClick={(e) => {
                    e.stopPropagation();
                }}
                placement={'bottom'} />,
            <CircleButton
                small
                key={`teamManagement-headerButton-delete`}
                className="__card-button-space"
                disabled={this.state.selectedStaffType === undefined || this.state.selectedStaffType.length === 0}
                icon={<FAIcon prefix='fad' name='trash-can' />}
                title={intl.formatMessage({ defaultMessage: 'Delete permanently' })}
                onClick={(e) => {
                    e.stopPropagation();
                    this.deleteStaffType(undefined, this.state.selectedStaffType);
                }}
                placement={'bottom'} />,
            <CircleButton
                small
                className="__card-button-space"
                key="staff-type-add-button"
                title={intl.formatMessage({ defaultMessage: 'Add an ability' })}
                icon={<FAIcon prefix='far' name='plus' />}
                placement="bottom"
                onClick={(e) => {
                    e.stopPropagation();
                    this.setState({ isShowStaffType: true, isEditStaffType: true, staffType: {}, staffTypeEditName: false });
                }} />,
        ];

        return (
            <Can rule={Rules.TeamManagement.Visit} redirect="/dashboard">
                <Container breadcrumb={[{ title: intl.formatMessage({ defaultMessage: 'Team management' }), link: "/team-management/list/users" }]}>
                    <Tabs animated onChange={this.onChangeTab} activeKey={this.props.match.params.tab ? this.props.match.params.tab : "users"} className="team-parent">
                        <Tabs.TabPane tab={
                            <Space>
                                <FAIcon prefix='fad' name='user' />
                                <FormattedMessage defaultMessage={'Users'} />
                            </Space>
                        } key="users">
                            <Card
                                className="team-card"
                                icon={<FAIcon prefix='fad' name='user' />}
                                title={<FormattedMessage defaultMessage={'Users'} />}
                                headerElements={[
                                    isCrm ?
                                        <Popover
                                            placement='bottomRight'
                                            overlayClassName='__poi-image-popover'
                                            title={<FormattedMessage defaultMessage={'Help'} />}
                                            trigger={'click'}
                                            content={
                                                <div>
                                                    <p><b><FormattedMessage defaultMessage={'Border color for user images'} />{':'}</b></p>
                                                    <span style={{ display: 'flex', alignItems: 'center' }}><p style={{ color: '#4caf50', fontSize: '30px', paddingRight: '5px', lineHeight: '20px' }}>•</p> <FormattedMessage defaultMessage={'Connected to the mobile application'} /></span>
                                                    <span style={{ display: 'flex', alignItems: 'center' }}><p style={{ color: '#ffee58', fontSize: '30px', paddingRight: '5px', lineHeight: '20px' }}>•</p> <FormattedMessage defaultMessage={'Not connected to the mobile application. (sms)'} /></span>
                                                    <span style={{ display: 'flex', alignItems: 'center' }}><p style={{ color: '#f44336', fontSize: '30px', paddingRight: '5px', lineHeight: '20px' }}>•</p> <FormattedMessage defaultMessage={'No existing means of communication.'} /></span>
                                                </div>
                                            }
                                        >
                                            <CircleButton
                                                small
                                                icon={<FAIcon prefix='fad' name='question' />}
                                                title={intl.formatMessage({ defaultMessage: 'Help' })}
                                                placement="topLeft"
                                                key="team-management-help" />
                                        </Popover>
                                        :
                                        <>
                                            <>
                                                <SpaceContent>
                                                    <Dropdown
                                                        trigger={['click']}
                                                        disabled={selectedUsers.length === 0 || loadingActivateReport || loadingArchiveUsers || loadingDearchiveUsers || loadingDeleteUsers || loadingDisableReport}
                                                        menu={{
                                                            items: [
                                                                {
                                                                    label: <FormattedMessage defaultMessage={'Users'} />,
                                                                    key: `export-users`,
                                                                    icon: <FAIcon prefix='fad' name='users' />,
                                                                    onClick: () => this.exportUsers(),
                                                                },
                                                                {
                                                                    label: <FormattedMessage defaultMessage={'Files'} />,
                                                                    key: `export-files`,
                                                                    icon: <FAIcon prefix='fad' name='folders' />,
                                                                    onClick: () => this.onExportUserFiles(),
                                                                }
                                                            ]
                                                        }}
                                                    >
                                                        <CircleButton
                                                            small
                                                            icon={<FAIcon prefix='fad' name='file-spreadsheet' />}
                                                            placement='topRight'
                                                            title={intl.formatMessage({ defaultMessage: '{count, plural, one {Export {count} user} other {Export {count} users}}' }, { count: this.state.selectedUsers.length })}
                                                        />
                                                    </Dropdown>
                                                    {
                                                        archived ?
                                                            null
                                                            :
                                                            <>
                                                                <CircleButton
                                                                    small
                                                                    icon={<FAIcon prefix='fad' name='file-minus' />}
                                                                    onClick={this.deactivateReports}
                                                                    disabled={selectedUsers.length === 0 || loadingActivateReport || loadingArchiveUsers || loadingDearchiveUsers || loadingDeleteUsers}
                                                                    loading={loadingDisableReport}
                                                                    title={intl.formatMessage({ defaultMessage: 'Deactivate report' })}
                                                                />
                                                                <CircleButton
                                                                    small
                                                                    icon={<FAIcon prefix='fad' name='file-plus' />}
                                                                    onClick={this.activateReports}
                                                                    disabled={selectedUsers.length === 0 || loadingArchiveUsers || loadingDearchiveUsers || loadingDeleteUsers || loadingDisableReport}
                                                                    loading={loadingActivateReport}
                                                                    title={intl.formatMessage({ defaultMessage: 'Activate report' })}
                                                                />
                                                            </>
                                                    }
                                                    {
                                                        archived ?
                                                            <CircleButton
                                                                small
                                                                icon={<FAIcon prefix='far' name='check' />}
                                                                onClick={this.activateUsers}
                                                                disabled={selectedUsers.length === 0 || loadingActivateReport || loadingArchiveUsers || loadingDeleteUsers || loadingDisableReport}
                                                                loading={loadingDearchiveUsers}
                                                                title={intl.formatMessage({ defaultMessage: '{count, plural, one {Activate {count} user} other {Activate {count} users}}' }, { count: this.state.selectedUsers.length })}
                                                            />
                                                            :
                                                            <CircleButton
                                                                small
                                                                icon={<FAIcon prefix='fad' name='box-archive' />}
                                                                onClick={this.deactivateUsers}
                                                                disabled={selectedUsers.length === 0 || loadingActivateReport || loadingDearchiveUsers || loadingDeleteUsers || loadingDisableReport}
                                                                loading={loadingArchiveUsers}
                                                                title={intl.formatMessage({ defaultMessage: '{count, plural, one {Deactivate {count} user} other {Deactivate {count} users}}' }, { count: this.state.selectedUsers.length })}
                                                            />
                                                    }
                                                    <DeleteButton
                                                        small
                                                        // primary
                                                        onConfirm={this.openDeleteUsersModal}
                                                        disabled={loadingDeleteUsers || selectedUsers.length === 0 || loadingActivateReport || loadingArchiveUsers || loadingDearchiveUsers || loadingDisableReport}
                                                        text={<FormattedMessage defaultMessage={'{count, plural, one {Delete {count} user} other {Delete {count} users}}'} values={{ count: this.state.selectedUsers.length }} />}
                                                    />
                                                </SpaceContent>
                                            </>
                                            <Divider dashed={true} style={{ borderLeft: '1px dashed rgba(0, 0, 0, 0.3)' }} type={'vertical'} />
                                            {integrations && checkPrivilege(Privileges.Integrations.Admin, this.props.currentUser) && // or any other integrations
                                                <Badge
                                                    offset={[-16.5, 2]}
                                                    count={(integrations.hotelaUsersToLink || 0) + (integrations.goUsersToLink || 0)} // + any other integrations 
                                                    size="small"
                                                    color={RED_COLOR}
                                                >
                                                    <CircleButton
                                                        small
                                                        title={intl.formatMessage({ defaultMessage: 'Link users' })}
                                                        onClick={() => this.setState({ showLinkedUsersModal: true })}
                                                        icon={<FAIcon prefix='fad' name='screen-users' />}
                                                    />
                                                </Badge>
                                            }
                                            <CircleButton
                                                small
                                                title={intl.formatMessage({ defaultMessage: 'Show connected users' })}
                                                onClick={() => this.setState({ showUserLoggedInModal: true })}
                                                icon={<FAIcon prefix='fad' name='link' />} />
                                            <CircleButton
                                                small
                                                onClick={() => this.goTo(`/${this.props.match.params.lang}/team-management/create-user`)}
                                                icon={<FAIcon prefix='fad' name='user-plus' />}
                                                title={intl.formatMessage({ defaultMessage: 'Add a user' })}
                                                placement="topLeft"
                                                key="team-management-add-user" />
                                            <CircleButton
                                                small
                                                onClick={() => this.setState({ displayImportUsers: true })}
                                                icon={<FAIcon prefix='fad' name='users-medical' />}
                                                title={intl.formatMessage({ defaultMessage: 'Add multiple users' })}
                                                placement="topLeft"
                                                key="team-management-add-multiple-user" />
                                        </>
                                ]}>
                                <div className="team-search-div">
                                    <span className="team-user-span" style={{ display: 'flex', gap: 8, justifyContent: 'center', alignItems: 'center' }}  >
                                        <InputField
                                            key={`search-${archived}`}
                                            onChange={this.handleSearchUsers}
                                            className="team-user-inputfield"
                                            type="search" />
                                        <p className='team-user-search-switch-p-wrapper'><Switch checked={archived} onChange={(e) => this.setState({ archived: e, selectedUsers: [], searchedUsers: undefined })} style={{ marginRight: '5px' }} /><FormattedMessage defaultMessage={'Archived only'} /></p>
                                        {
                                            isCrm &&
                                            <p style={{ marginLeft: "10px" }} className='team-user-search-switch-p-wrapper'><Switch checked={notAlreadyConnected} onChange={(e) => this.setState({ notAlreadyConnected: e, noneCommunication: noneCommunication ? !e : noneCommunication })} style={{ marginRight: '5px' }} /><FormattedMessage defaultMessage={'Not yet connected (no push)'} /></p>
                                        }
                                        {
                                            isCrm &&
                                            <p style={{ marginLeft: "10px" }} className='team-user-search-switch-p-wrapper'><Switch checked={noneCommunication} onChange={(e) => this.setState({ noneCommunication: e, notAlreadyConnected: notAlreadyConnected ? !e : notAlreadyConnected })} style={{ marginRight: '5px' }} /><FormattedMessage defaultMessage={'No means of communication (no push or sms)'} /></p>
                                        }
                                    </span>
                                </div>
                                <VirtualTable
                                    className={"__basic-table"}
                                    rowKey={(u: User) => u.id}
                                    dataSource={parsedUsers}
                                    columns={isCrm ? instructorsColumns : usersColumns(intl, getFullName, isSmartphone).filter(uc => (uc.key !== 'active' && (this.props.company && ['read-only', 'read-write'].includes(this.props.company.userCodeDisplayAuthorization)) || uc.key !== 'code')).map(uc => {
                                        if (uc.key === "staffType") {
                                            return {
                                                ...uc,
                                                filterMultiple: false,
                                                filters:
                                                    staffTypes ?
                                                        staffTypes.map(st => {
                                                            return {
                                                                text: st.name!,
                                                                value: st.id!,
                                                            };
                                                        }).concat({
                                                            text: intl.formatMessage({ defaultMessage: 'No ability' }),
                                                            value: -1,
                                                        })
                                                        :
                                                        [],
                                                onFilter: (value: string | number | boolean, record: User) => {
                                                    if (!record.staffType || record.staffType.length === 0) {
                                                        return value === -1;
                                                    } else {
                                                        return record.staffType.map(s => s.id!).includes(toInteger(value));

                                                    }
                                                },
                                            };
                                        } else {
                                            return uc;
                                        }
                                    })}
                                    loading={this.state.usersLoading}
                                    scroll={{ x: true, y: tableHeight }}
                                    onRow={isCrm ? undefined : this.usersOnRow}
                                    rowSelection={isCrm ? undefined : { type: 'checkbox', onChange: this.onChangeSelectedUsers, selectedRowKeys: this.state.selectedUsers }} />
                            </Card>
                            <ImportUsers
                                displayDrawer={this.state.displayImportUsers}
                                closeDrawer={this.closeImportAndRefresh}
                            />
                        </Tabs.TabPane>
                        <Tabs.TabPane tab={
                            <Space>
                                <FAIcon prefix='fad' name='users' />
                                <FormattedMessage defaultMessage={'Groups'} />
                            </Space>
                        } key="groups">
                            <Card
                                className="team-card"
                                icon={<FAIcon prefix='fad' name='users' />}
                                title={<FormattedMessage defaultMessage={'Groups'} />}
                                headerElements={[
                                    !isCrm &&
                                    <CircleButton
                                        small
                                        title={intl.formatMessage({ defaultMessage: 'Add a group' })}
                                        onClick={() => this.setState({ editGroup: true, selectedGroupUsers: [], nameGroupEdit: '' })}
                                        icon={<FAIcon prefix='fad' name='users-medical' />}
                                        placement="topLeft"
                                        key="team-management-add-group" />
                                ]}>
                                <InputField
                                    onChange={this.handleSearchGroups}
                                    style={{ width: '300px', marginBottom: '10px' }}
                                    type="search" />
                                <VirtualTable
                                    className={"__basic-table"}
                                    rowKey={(g: Group) => `team-management-group-${g.id}`}
                                    dataSource={this.state.searchedGroups ? this.state.searchedGroups.sort((a, b) => a.name ? b.name ? a.name!.localeCompare(b.name!) : -1 : 1) : this.props.groups?.sort((a, b) => a.name ? b.name ? a.name!.localeCompare(b.name!) : -1 : 1)}
                                    columns={groupsColumns.filter(gc => gc.key !== "is_admin")}
                                    loading={this.state.groupsLoading}
                                    scroll={{ x: true, y: tableHeight }}
                                    onRow={this.groupsOnRow} />
                            </Card>
                            <Drawer
                                destroyOnClose={true}
                                // zIndex={1011}
                                className="__drawer"
                                title={<FormattedMessage defaultMessage={'Create a group'} />}
                                width={this.props.isSmartphone ? '100%' : '512px'}
                                onClose={() => this.setState({ editGroup: false })}
                                visible={this.state.editGroup}
                                footer={
                                    <div
                                        style={{
                                            textAlign: 'right',
                                        }}
                                    >
                                        {
                                            <>
                                                <Button onClick={() => this.setState({ editGroup: false, nameGroupEdit: "", nameGroupEditError: '', selectedGroupUsers: [] })} style={{ marginRight: 8 }}>
                                                    <FormattedMessage defaultMessage={'Cancel'} />
                                                </Button>
                                                <Button loading={this.state.loadingGroupEdit} type="primary" onClick={() => this.handleSubmitGroup()} style={{ marginRight: 8 }}>
                                                    <FormattedMessage defaultMessage={'Create'} />
                                                </Button>
                                            </>
                                        }
                                    </div>
                                }
                            >
                                <Row gutter={[10, 20]}>
                                    <Col xs={{ span: 24 }} md={{ span: 24 }}>
                                        <InputField
                                            error={this.state.nameGroupEditError}
                                            onChange={(e: InputFieldOnChangeEvent) => this.setState({ nameGroupEdit: e.target.value, nameGroupEditError: '' })}
                                            value={this.state.nameGroupEdit}
                                            placeholder={`${intl.formatMessage({ defaultMessage: 'Group name' })}*`} />
                                    </Col>
                                </Row>
                                <Row gutter={[10, 20]} style={{ marginTop: '20px' }}>
                                    <Col xs={{ span: 24 }} md={{ span: 24 }}>
                                        <Select
                                            dropdownClassName="__edit-group-select"
                                            mode="multiple"
                                            allowClear
                                            style={{ width: '100%' }}
                                            listHeight={450}
                                            placeholder={intl.formatMessage({ defaultMessage: 'Users' })}
                                            onChange={(e) => this.setState({ selectedGroupUsers: e })}
                                            value={this.state.selectedGroupUsers}
                                            filterOption={true}
                                            optionFilterProp="label"
                                            showArrow
                                            maxTagCount={"responsive"}
                                        >
                                            {
                                                this.props.users.sort((a, b) => a.last_name.localeCompare(b.last_name)).map((o) => {
                                                    return <Select.Option label={`${o.last_name} ${o.first_name}`} value={o.id} key={"group-add-users-" + o.id}>{`${o.last_name} ${o.first_name}`}</Select.Option>;
                                                })
                                            }
                                        </Select>
                                    </Col>
                                </Row>
                            </Drawer>
                        </Tabs.TabPane>
                        {
                            !checkPrivilege(Privileges.CRM.Visit, this.props.currentUser) &&
                            <Tabs.TabPane tab={
                                <Space>
                                    <FAIcon prefix='fad' name='flask-gear' />
                                    <FormattedMessage defaultMessage={'Abilities'} />
                                </Space>
                            } key="staff-types">
                                <Card
                                    className="team-card"
                                    icon={<FAIcon prefix='fad' name='flask-gear' />}
                                    title={<FormattedMessage defaultMessage={'Abilities'} />}
                                    headerElements={[
                                        this.props.isSmartphone ?
                                            <SpeedDial
                                                key='staff-type-actions-speeddial'
                                                title={intl.formatMessage({ defaultMessage: 'Actions' })}
                                                icon={<FAIcon prefix='fad' name='ellipsis-vertical' />}
                                                openIcon={<FAIcon prefix='fad' name='ellipsis' />}
                                                buttons={headerButtons} />
                                            :
                                            headerButtons
                                    ]}>
                                    <VirtualTable
                                        className={"__basic-table"}
                                        rowKey={(g: StaffType) => `staff-type-${g.id}`}
                                        dataSource={staffTypes}
                                        scroll={{ x: true, y: tableHeight + 43 }}
                                        columns={this.staffTypeColumns}
                                        onRow={this.staffTypeOnRow}
                                        rowSelection={{ type: 'checkbox', onChange: this.onChangeSelectedStaffType, selectedRowKeys: this.state.selectedStaffType }}
                                        locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<FormattedMessage defaultMessage={'No ability'} />} /> }}
                                    />
                                    <Drawer
                                        destroyOnClose={true}
                                        width={this.props.isSmartphone ? '100%' : '100%'}
                                        title={
                                            this.state.staffType?.id && !this.state.staffTypeEditName ?
                                                <Space>
                                                    <span>{this.state.staffType?.name}</span>
                                                    <FAIcon prefix='fad' name='pencil'
                                                        className='__single-line-element'
                                                        title={intl.formatMessage({ defaultMessage: 'Edit name' })}
                                                        onClick={() => this.setState({ staffTypeEditName: true })} />
                                                </Space>
                                                :
                                                <Space>
                                                    {
                                                        this.state.staffType == null || this.state.staffType.id == null ?
                                                            <span><FormattedMessage defaultMessage={'Create an ability'} />{':'}</span>
                                                            : null
                                                    }
                                                    < InputField
                                                        onChange={(e) => {
                                                            this.setState((state) => {
                                                                const staffTypeCopy = state.staffType ? { ...state.staffType } : undefined;
                                                                if (staffTypeCopy) {
                                                                    staffTypeCopy.name = e.target.value;
                                                                }
                                                                return { staffType: staffTypeCopy };
                                                            });
                                                        }}
                                                        value={this.state.staffType?.name}
                                                    />
                                                </Space>
                                        }
                                        placement="right"
                                        onClose={() => this.closeStaffTypeShowDrawer()}
                                        open={this.state.isShowStaffType}
                                        className="__drawer"
                                        footer={
                                            <div style={{ textAlign: 'right', }} >
                                                {
                                                    <>
                                                        <Button onClick={() => this.closeStaffTypeShowDrawer()} style={{ marginRight: 8 }}>
                                                            <FormattedMessage defaultMessage={'Close'} />
                                                        </Button>
                                                        <Button onClick={() => this.updateStaffType()} type="primary">
                                                            <FormattedMessage defaultMessage={'Save'} />
                                                        </Button>
                                                    </>
                                                }
                                            </div>
                                        }
                                    >
                                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
                                            {this.state.staffType && <StaffTypeShowForm staffTypeShow={this.state.staffType} users={this.props.users} setStaffTypeEdit={(staffType) => this.setState({ staffType })} />}
                                            <Row gutter={[10, 10]}>
                                                {
                                                    this.state.displayAlert &&
                                                    <Col style={{ marginTop: '10px' }} xs={{ span: 24 }}>
                                                        <Alert
                                                            afterClose={() => this.setState({ displayAlert: false })}
                                                            message={this.state.alertMessage}
                                                            type={this.state.alertType}
                                                            showIcon closable
                                                        />
                                                    </Col>
                                                }
                                            </Row>
                                        </div>
                                    </Drawer>
                                </Card>
                            </Tabs.TabPane>
                        }
                    </Tabs>
                </Container>
                <Modal
                    open={this.state.toDeleteUsers !== undefined && this.state.toDeleteUsers.length > 0}
                    onCancel={() => this.setState({ toDeleteUsers: undefined })}
                    title={
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 8 }}>
                            <FAIcon prefix="fas" name="triangle-exclamation" color={RED_COLOR} />
                            <FormattedMessage defaultMessage={'Permanently remove'} />
                        </div>
                    }
                    closable={!this.state.loadingDeleteUsers}
                    maskClosable={!this.state.loadingDeleteUsers}
                    destroyOnClose={true}
                    width={this.props.windowWidth < 1200 ? this.props.windowWidth < 800 ? '98%' : '60%' : '35%'}
                    footer={[null, null,
                        <Space key={`overview-modal-ok-button`}>
                            <Button disabled={this.state.loadingDeleteUsers} type={'dashed'} key={`overview-modal-ok-button-1`} onClick={() => this.setState({ toDeleteUsers: undefined })} ><FormattedMessage defaultMessage={'Cancel'} /></Button>
                            <Button loading={this.state.loadingDeleteUsers} type={'primary'} style={{ backgroundColor: RED_COLOR }} key={`overview-modal-ok-button-2`} disabled={!confirmDeletion} onClick={() => this.deleteUsers()} ><FormattedMessage defaultMessage={'Delete'} /></Button>
                        </Space>
                    ]}
                >
                    <div>
                        <p><FormattedMessage defaultMessage={'This action is irreversible and selected items will be removed permanently.'} /></p>
                        <p><FormattedMessage defaultMessage={'User and related data will be deleted.'} /></p>
                        <br />
                        <List
                            className="teamanagement-users-removal-list"
                            itemLayout="horizontal"
                            dataSource={this.props.users.filter(u => toDeleteUsers?.includes(u.id))}
                            pagination={{
                                defaultPageSize: 5,
                                hideOnSinglePage: true,
                                showTotal: (total, range) => <FormattedMessage defaultMessage={'{range0}-{range1} of {total} users'} values={{ range0: range[0], range1: range[1], total }} />
                            }}
                            renderItem={item => (
                                <List.Item
                                    actions={[<div style={{ cursor: 'pointer' }} key='xmark-icon-button' onClick={() => this.setState({ toDeleteUsers: toDeleteUsers?.filter(u => u !== item.id) })}><FAIcon prefix='fas' name='xmark' /></div>,]}
                                >
                                    <List.Item.Meta
                                        style={{ alignItems: 'center' }}
                                        avatar={<Avatar src={item.image} />}
                                        title={<span>{item.first_name + ' ' + item.last_name}</span>}
                                        description={`${item.email} (${item.username})`}
                                    />
                                </List.Item>
                            )}
                        />
                        <br />
                        <br />
                        <p>
                            <Checkbox
                                checked={confirmDeletion}
                                onChange={(e) => {
                                    this.setState({ confirmDeletion: e.target.checked });
                                }}
                            >
                                <FormattedMessage defaultMessage={'I confirm that I am aware and understand that the following action is irreversible.'} />
                            </Checkbox>
                        </p>
                    </div>
                </Modal>
                <Modal
                    open={this.state.showLinkedUsersModal}
                    onCancel={() => this.setState({ showLinkedUsersModal: false })}
                    title={
                        <div key={'header-integration-chooser'} style={{ display: 'flex', justifyContent: 'start', alignItems: 'center', gap: 8 }}>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 8 }}>
                                <span><FormattedMessage defaultMessage={'Integration'} />{': '}</span>
                                <Select
                                    style={{ width: 220 }}
                                    size={'large'}
                                    value={this.state.selectedIntegration}
                                    onChange={(selectedIntegration) => this.setState({ selectedIntegration })}
                                    options={[
                                        { 'label': "Hotela", 'value': 'hotela' },
                                        { 'label': 'Global Office', 'value': 'globaloffice' },
                                        { 'label': 'OfficeMaker', 'value': 'officemaker' },
                                    ].filter(opt => {
                                        if (integrations) {
                                            if (integrations.hotelaEnabled && opt.value === 'hotela') return true;
                                            if (integrations.officeMakerEnabled && opt.value === 'officemaker') return true;
                                            if (integrations.globalOfficeEnabled && opt.value === 'globaloffice') return true;
                                        }
                                        return false;
                                    })}
                                />
                            </div>
                            <Divider dashed={true} style={{ borderLeft: '1px dashed rgba(0, 0, 0, 0.3)' }} type={'vertical'} />
                            <CircleButton
                                small
                                title={intl.formatMessage({ defaultMessage: 'Force reload' })}
                                icon={<FAIcon prefix='fad' name='rotate' />}
                                onClick={() => this.refreshIntegrationUsers()}
                            />
                        </div>
                    }
                    destroyOnClose={true}
                    width={this.props.windowWidth < 1200 ? '98%' : '70%'}
                    footer={[null, null,
                        <Button type={'primary'} key={`integrations-modal-ok-button`} onClick={() => this.setState({ showLinkedUsersModal: false })} ><FormattedMessage defaultMessage={'Close'} /></Button>
                    ]}
                >
                    <UsersManagement isModal ref={this.ref} selectedIntegration={this.state.selectedIntegration} />
                </Modal>
                <Modal
                    open={this.state.showUserLoggedInModal}
                    onCancel={() => this.setState({ showUserLoggedInModal: false })}
                    title={
                        <div>
                            <FormattedMessage defaultMessage={'Users connected on'} />
                            &nbsp;
                            <Select
                                value={loggedInType}
                                onChange={(value) => this.setState({ loggedInType: value })}
                                options={[
                                    { 'label': <FormattedMessage defaultMessage={'All devices'} />, 'value': 'all' },
                                    { 'label': 'Sunkhronos Admin', 'value': 'admin' },
                                    { 'label': 'Sunkhronos Mobile', 'value': 'mobile' },
                                    { 'label': <FormattedMessage defaultMessage={'Timeclock'} />, 'value': 'timeclock' },
                                    { 'label': <FormattedMessage defaultMessage={'A device without identification'} />, 'value': 'UNKNOWN' }
                                ]} />
                        </div>
                    }
                    destroyOnClose={true}
                    width={this.props.windowWidth < 1200 ? '98%' : '70%'}
                    footer={[null, null,
                        <Button type={'primary'} key={`overview-modal-ok-button`} onClick={() => this.setState({ showUserLoggedInModal: false })} ><FormattedMessage defaultMessage={'Close'} /></Button>
                    ]}
                >
                    <UserLogedIn type={loggedInType} />
                </Modal>
            </Can >
        );

    }

    staffTypeColumnsActions = (record: StaffType) => {
        return [
            <DeleteButton
                small
                key="staff-type-action-delete"
                style={{ marginLeft: '5px' }}
                text={<FormattedMessage defaultMessage={'Do you want to delete this ability?'} />}
                onConfirm={e => {
                    e?.stopPropagation();
                    this.deleteStaffType(record);
                }} />,
            <CircleButton
                small
                key="staff-type-action-edit"
                className="__card-button-space"
                icon={<FAIcon prefix='fad' name='pencil' />}
                title={this.props.intl.formatMessage({ defaultMessage: 'Edit' })}
                placement="top"
                onClick={(e) => {
                    e.stopPropagation();
                    this.setState({ isShowStaffType: true, isEditStaffType: true, staffType: { ...record } });
                }}
            />
        ];
    };

    staffTypeColumns: ColumnProps<StaffType>[] = [
        {
            title: <FormattedMessage defaultMessage={'Title'} />,
            dataIndex: 'name',
            key: 'name',
            className: '__min-width_300',
            sorter: (a: StaffType, b: StaffType) => a.name! < b.name! ? -1 : 1
        },
        {
            title: <FormattedMessage defaultMessage={'Users'} />,
            key: 'nbUsers',
            className: '__width_120 __centered-text',
            render: (record: StaffType) => {
                return record.users ? record.users?.length : 0;
            }
        },
        {
            title: <FormattedMessage defaultMessage={'Actions'} />,
            key: 'actions',
            className: '__width_120 __centered-text',
            render: (record: StaffType) => {
                return (
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        {
                            this.props.isSmartphone ?
                                <SpeedDial
                                    small
                                    key='staff-type-actions-speeddial'
                                    title={this.props.intl.formatMessage({ defaultMessage: 'Actions' })}
                                    icon={<FAIcon prefix='fad' name='ellipsis-vertical' />}
                                    openIcon={<FAIcon prefix='fad' name='ellipsis' />}
                                    buttons={this.staffTypeColumnsActions(record)} />
                                :
                                this.staffTypeColumnsActions(record)
                        }
                    </div>

                );
            },
        }
    ];
}

const mapDispatchToProps = (dispatch: StoreDispatch) => ({
    changeUsers: (u: User[]) => dispatch(changeUsers(u)),
    changeGroups: (g: Group[]) => dispatch(changeGroups(g)),
    changeStaffTypes: (s: StaffType[]) => dispatch(changeStaffTypes(s)),
});

const mapStateToProps = (state: ApplicationState) => ({
    users: state.teamManagement.users,
    groups: state.teamManagement.groups,
    isSmartphone: state.window.isSmartphone,
    currentUser: state.user.currentUser,
    height: state.window.height,
    staffTypes: state.configurations.staffTypes,
    windowWidth: state.window.width,
    company: state.user.company,
    integrations: state.integrations.enabledIntegrations.data
});

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(injectIntl(withFullName(TeamManagement)));